import React from "react"
import styled from "styled-components"
import classNames from "classnames"
import { BaseButton } from "../BaseButton/Button"
import { darken } from "polished"

const Container = styled.button`
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    border-radius: 5px;
    transition: all ease-in-out 200ms;
    background: none;
    border: none;
    text-decoration: none;
    color: inherit;
    padding: 0;
    font-size: inherit;
    outline: none;

    &:hover {
        color: #2563eb;
        text-decoration: underline;
    }

    &.primary {
        color: ${({ theme }) => theme.primary};
    }

    &.underline {
        text-decoration: underline;
    }

    &:focus-visible {
        background: #e0f2fe;
        color: #0284c7 !important;
        box-shadow: 0 0 0 3rem rgba(66, 153, 225, 0.5) !important;
    }

    &[disabled] {
        pointer-events: none;
    }

    &.justify-flex-start {
        justify-content: flex-start;
    }

    &.justify-center {
        justify-content: center;
    }

    &.justify-flex-end {
        justify-content: flex-end;
    }

    &.justify-space-between {
        justify-content: space-between;
    }
`

interface ITextButton
    extends React.FC<
        BaseButton &
            React.ButtonHTMLAttributes<HTMLButtonElement> & {
                underline?: boolean
                fontWeight?: string | number
            }
    > {}

export const TextButton: ITextButton = ({
    children,
    size,
    color,
    fontWeight = "inherit",
    disabled = false,
    justify = "flex-start",
    ...props
}) => {
    return (
        <Container
            disabled={disabled}
            className={classNames(
                props.className ?? "",
                size,
                color,
                `justify-${justify}`,
                {
                    underline: props.underline,
                }
            )}
            style={{
                fontWeight,
            }}
        >
            {children}
        </Container>
    )
}
