import React, { ReactNode } from "react"
import styled from "styled-components"

const Layout = styled.div`
    display: flex;
    flex-direction: column;
    border-radius: 5rem;
    border-left: 5rem solid #333;
    margin: 10rem 0;

    &.info {
        background: #eff6ff;
        color: #1e3a8a;
        border-left-color: #93c5fd;
    }

    &.warning {
        background: #fffbeb;
        color: #78350f;
        border-left-color: #fcd34d;
    }

    &.danger {
        background: #fef2f2;
        color: #7f1d1d;
        border-left-color: #fca5a5;
    }

    &.success {
        background: #ecfdf5;
        color: #064e3b;
        border-left-color: #6ee7b7;
    }

    > div {
        padding: 15rem 20rem;
    }

    .title {
        font-size: 25rem;
        font-family: "Gilroy-SemiBold", sans-serif;
        padding-bottom: 0;
        margin-top: 5rem;
    }

    .body {
        font-size: 18rem;
        line-height: 28rem;
    }
`

interface Hint {
    variant?: "info" | "success" | "warning" | "danger"
    title?: string
    body: ReactNode | JSX.Element | string
}

export const Hint = ({ body, title, variant = "info" }: Hint) => {
    return (
        <Layout className={`hint ${variant}`}>
            {title && <div className={"title"}>{title}</div>}
            <div className={"body"}>{body}</div>
        </Layout>
    )
}
