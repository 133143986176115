export type PaletteNames =
    | "blue"
    | "grey"
    | "blueGrey"
    | "coolGrey"
    | "red"
    | "orange"
    | "yellow"
    | "indigo"
    | "emerald"
    | "pink"
    | "purple"
    | "rose"
    | "teal"

export type Color = {
    light: string
    main: string
    dark: string
    "50": string
    "100": string
    "200": string
    "300": string
    "400": string
    "500": string
    "600": string
    "700": string
    "800": string
    "900": string
}

export const blue: Color = {
    light: "#BFDBFE",
    main: "#3B82F6",
    dark: "#1D4ED8",
    "50": "#EFF6FF",
    "100": "#DBEAFE",
    "200": "#BFDBFE",
    "300": "#93C5FD",
    "400": "#60A5FA",
    "500": "#3B82F6",
    "600": "#2563EB",
    "700": "#1D4ED8",
    "800": "#1E40AF",
    "900": "#1E3A8A",
}

export const grey: Color = {
    light: "#E5E7EB",
    main: "#6B7280",
    dark: "#374151",
    "50": "#F9FAFB",
    "100": "#F3F4F6",
    "200": "#E5E7EB",
    "300": "#D1D5DB",
    "400": "#9CA3AF",
    "500": "#6B7280",
    "600": "#4B5563",
    "700": "#374151",
    "800": "#1F2937",
    "900": "#111827",
}

export const blueGrey: Color = {
    light: "#E2E8F0",
    main: "#64748B",
    dark: "#334155",
    "50": "#F8FAFC",
    "100": "#F1F5F9",
    "200": "#E2E8F0",
    "300": "#CBD5E1",
    "400": "#94A3B8",
    "500": "#64748B",
    "600": "#475569",
    "700": "#334155",
    "800": "#1E293B",
    "900": "#0F172A",
}

export const coolGrey: Color = {
    light: "#E5E7EB",
    main: "#6B7280",
    dark: "#374151",
    "50": "#F9FAFB",
    "100": "#F3F4F6",
    "200": "#E5E7EB",
    "300": "#D1D5DB",
    "400": "#9CA3AF",
    "500": "#6B7280",
    "600": "#4B5563",
    "700": "#374151",
    "800": "#1F2937",
    "900": "#111827",
}

export const red: Color = {
    light: "#FECACA",
    main: "#EF4444",
    dark: "#B91C1C",
    "50": "#FEF2F2",
    "100": "#FEE2E2",
    "200": "#FECACA",
    "300": "#FCA5A5",
    "400": "#F87171",
    "500": "#EF4444",
    "600": "#DC2626",
    "700": "#B91C1C",
    "800": "#991B1B",
    "900": "#7F1D1D",
}

export const orange: Color = {
    light: "#FED7AA",
    main: "#F97316",
    dark: "#C2410C",
    "50": "#FFF7ED",
    "100": "#FFEDD5",
    "200": "#FED7AA",
    "300": "#FDBA74",
    "400": "#FB923C",
    "500": "#F97316",
    "600": "#EA580C",
    "700": "#C2410C",
    "800": "#9A3412",
    "900": "#7C2D12",
}

export const yellow: Color = {
    light: "#FEF08A",
    main: "#EAB308",
    dark: "#A16207",
    "50": "#FEFCE8",
    "100": "#FEF9C3",
    "200": "#FEF08A",
    "300": "#FDE047",
    "400": "#FACC15",
    "500": "#EAB308",
    "600": "#CA8A04",
    "700": "#A16207",
    "800": "#854D0E",
    "900": "#713F12",
}

export const indigo: Color = {
    light: "#C7D2FE",
    main: "#6366F1",
    dark: "#4338CA",
    "50": "#EEF2FF",
    "100": "#E0E7FF",
    "200": "#C7D2FE",
    "300": "#A5B4FC",
    "400": "#818CF8",
    "500": "#6366F1",
    "600": "#4F46E5",
    "700": "#4338CA",
    "800": "#3730A3",
    "900": "#312E81",
}

export const emerald: Color = {
    light: "#A7F3D0",
    main: "#10B981",
    dark: "#047857",
    "50": "#ECFDF5",
    "100": "#D1FAE5",
    "200": "#A7F3D0",
    "300": "#6EE7B7",
    "400": "#34D399",
    "500": "#10B981",
    "600": "#059669",
    "700": "#047857",
    "800": "#065F46",
    "900": "#064E3B",
}

export const pink: Color = {
    light: "#FBCFE8",
    main: "#EC4899",
    dark: "#BE185D",
    "50": "#FDF2F8",
    "100": "#FCE7F3",
    "200": "#FBCFE8",
    "300": "#F9A8D4",
    "400": "#F472B6",
    "500": "#EC4899",
    "600": "#DB2777",
    "700": "#BE185D",
    "800": "#9D174D",
    "900": "#831843",
}

export const violet: Color = {
    light: "#F5F3FF",
    main: "#8B5CF6",
    dark: "#6D28D9",
    "50": "#F5F3FF",
    "100": "#EDE9FE",
    "200": "#DDD6FE",
    "300": "#C4B5FD",
    "400": "#A78BFA",
    "500": "#8B5CF6",
    "600": "#7C3AED",
    "700": "#6D28D9",
    "800": "#5B21B6",
    "900": "#4C1D95",
}

export const purple: Color = {
    light: "#E9D5FF",
    main: "#A855F7",
    dark: "#7E22CE",
    "50": "#FAF5FF",
    "100": "#F3E8FF",
    "200": "#E9D5FF",
    "300": "#D8B4FE",
    "400": "#C084FC",
    "500": "#A855F7",
    "600": "#9333EA",
    "700": "#7E22CE",
    "800": "#6B21A8",
    "900": "#581C87",
}

export const rose: Color = {
    light: "#FECDD3",
    main: "#F43F5E",
    dark: "#BE123C",
    "50": "#FFF1F2",
    "100": "#FFE4E6",
    "200": "#FECDD3",
    "300": "#FDA4AF",
    "400": "#FB7185",
    "500": "#F43F5E",
    "600": "#E11D48",
    "700": "#BE123C",
    "800": "#9F1239",
    "900": "#881337",
}

export const teal: Color = {
    light: "#99F6E4",
    main: "#14B8A6",
    dark: "#0F766E",
    "50": "#F0FDFA",
    "100": "#CCFBF1",
    "200": "#99F6E4",
    "300": "#5EEAD4",
    "400": "#2DD4BF",
    "500": "#14B8A6",
    "600": "#0D9488",
    "700": "#0F766E",
    "800": "#115E59",
    "900": "#134E4A",
}
