import React, { memo } from "react"
import styled from "styled-components"
import { blueGrey } from "../../utils/colors"
import { Link } from "gatsby"
import { TextButton } from "../Ui/TextButton/TextButton"

const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10rem;
    margin-top: 80rem;
    padding-bottom: 50rem;

    > .header {
        font-family: Gilroy-SemiBold, sans-serif;
        color: ${blueGrey[600]};
        padding: 50rem 0 0;
        border-top: 1rem solid ${blueGrey[200]};
    }
`

interface FurtherReadingProps {
    items: Array<{
        title: string
        to: string
    }>
}

export const FurtherReading = memo(({ items }: FurtherReadingProps) => {
    return (
        <Container>
            <div className={"header"}>Related content</div>
            {items.map((link, i) => {
                return (
                    <Link key={`fr-${i}`} to={link.to}>
                        <TextButton color={"primary"}>
                            {link.title}
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="icon icon-tabler icon-tabler-chevron-right"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                strokeWidth="1.5"
                                stroke="currentColor"
                                fill="none"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            >
                                <desc>
                                    Download more icon variants from
                                    https://tabler-icons.io/i/chevron-right
                                </desc>
                                <path
                                    stroke="none"
                                    d="M0 0h24v24H0z"
                                    fill="none"
                                ></path>
                                <polyline points="9 6 15 12 9 18"></polyline>
                            </svg>
                        </TextButton>
                    </Link>
                )
            })}
        </Container>
    )
})
